<template>
  <div id="navi">
    <!-- <b-button v-b-toggle.sidebar-backdrop class="fixed-top">Toggle Sidebar</b-button> -->
    <div class="fixed-top text-left ml-3 mt-2 mx-2 my-2">
      <!-- <b-avatar
        v-b-toggle.sidebar-backdrop
        :src="require('@/assets/menu.png')"
        size="2.5rem"
        variant="light"
        class="mt-2"
      ></b-avatar> -->
      <svg v-b-toggle.sidebar-backdrop 
        variant="light" class="mt-2" width="40" height="40" style="outline: none;" version="1.1" viewBox="0 0 23.019 23.019">
<path fill="#FED532" d="m11.56 0.12858c-6.3057 3e-6 -11.43 5.1241-11.43 11.43 0 6.3057 5.1241 11.432 11.43 11.432 2.679 0 5.148-0.92445 7.0972-2.4706a0.586 0.586 0 0 0 0.0961-0.82424 0.586 0.586 0 0 0-0.82424-0.09405c-1.7502 1.3884-3.9593 2.2149-6.3691 2.2149-5.6723 3e-6 -10.258-4.5855-10.258-10.258 0-5.6723 4.586-10.258 10.258-10.258 5.6723 3e-6 10.258 4.5855 10.258 10.258 0 1.8452-0.48558 3.5738-1.3364 5.0684a0.586 0.586 0 0 0 0.2191 0.79892 0.586 0.586 0 0 0 0.79892-0.22066c0.94814-1.6657 1.4898-3.5954 1.4898-5.6467 0-6.3057-5.1236-11.43-11.429-11.43zm5.887 6.8781a0.64433 0.66222 0 0 0-0.63201 0.66197 0.64433 0.66222 0 0 0 0.64441 0.66249 0.64433 0.66222 0 0 0 0.64388-0.66249 0.64433 0.66222 0 0 0-0.64388-0.66197 0.64433 0.66222 0 0 0-0.0124 0zm-12.335 0.03049c-0.34957-1.78e-4 -0.63269 0.28295-0.63252 0.63252 9e-4 0.3488 0.28371 0.63115 0.63252 0.63097h10.256c0.3488 1.72e-4 0.63162-0.28217 0.63252-0.63097 1.7e-4 -0.34956-0.28296-0.63269-0.63252-0.63252zm0 4.07a0.6325 0.6325 0 0 0-0.63252 0.63304 0.6325 0.6325 0 0 0 0.63252 0.63252h12.897a0.6325 0.6325 0 0 0 0.63045-0.63252 0.6325 0.6325 0 0 0-0.63045-0.63304zm0 4.0721a0.6325 0.6325 0 0 0-0.63252 0.63303 0.6325 0.6325 0 0 0 0.63252 0.63304h10.805a0.6325 0.6325 0 0 0 0.63252-0.63304 0.6325 0.6325 0 0 0-0.63252-0.63303z" color="#000000" image-rendering="auto" solid-color="#000000"/>
</svg>
    </div>

    <b-sidebar
      id="sidebar-backdrop"
      title=""
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <div class="px-3 py-2">
        <div class="d-flex align-items-center mb-4">
          <b-avatar
          :src="require('@/assets/avatar.png')"
          class="mr-3" 
          size="4rem"></b-avatar>
          <span class="mr-auto text-white"> {{ msisdn }}</span>
        </div>
        <nav class="mb-3 ml-4">
          <b-nav vertical class="text-left">
            <!-- <b-nav-item class="align-items-center d-flex mb-1">
              <b-icon icon="cart-check-fill" class="text-white" font-scale="2"></b-icon>
              <router-link to="/store" class="ml-3 text-white">UB Store</router-link>
            </b-nav-item> -->
            <!-- <b-nav-item class="align-items-center d-flex mb-1">
              <b-icon icon="star-fill" class="text-white" font-scale="2"></b-icon>
              <router-link to="/redeem" class="ml-3 text-white">Redeem Reward</router-link>
            </b-nav-item> -->

            <b-link to="/" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="house-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Home</span>
            </b-link>
            <b-link to="/inbox" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="envelope-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Inbox</span>
            </b-link>
            <b-link to="/leaderboard" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="trophy-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Leaderboard</span>
            </b-link>
            <b-link to="/redeem" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="star-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Redeem</span>
            </b-link>
            <b-link to="/store" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="cart-check-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Store</span>
            </b-link>
            <!-- <b-link to="/faq" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="star-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Cara Bermain</span>
            </b-link> -->
            <b-link to="/tnc" class="align-items-center text-decoration-none d-flex mb-3">
              <b-icon icon="file-earmark-text-fill" class="text-white" font-scale="2"></b-icon>
              <span class="ml-3 text-white">Syarat & Ketentuan</span>
            </b-link>
          </b-nav>
        </nav>
        <b-button variant="primary" block @click="handleClick">
          <b-icon icon="power" aria-hidden="true"></b-icon> Logout
        </b-button>
      </div>
    </b-sidebar>

    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">Leaderboard</router-link> |
      <a href="javascript:void(0)" @click="handleClick">Logout</a> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "Nav",
  data() {
    return {
      msisdn: this.$store.state.user
    };
  },
  methods: {
    ...mapMutations({
      setToken: 'SET_TOKEN',
      setUser: 'SET_USER',
  }),
    handleClick() {
      // this.$store.state.user = null;
      this.deleteStore();
      this.$router.push({ name: "login" });
      // location.replace('/login');
    },
    deleteStore() {
      this.setToken()
      this.setUser()
    }
  },
};
</script>
<style scoped>
#navi {
  z-index: 1000;
}
</style>
