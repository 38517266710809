<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6 text-left" style="font-size:.9rem">
    <h6>CARA MENDAPATKAN SKOR</h6>
    <p>
      <ul class="pl-4">
        <li>Pemain harus mengenai icon bintang untuk mendapatkan skor</li>
        <li>1 icon bintang menjadi 1 skor yang didapatkan</li>
        <li>Skor di akumulasikan untuk semua game</li>
        <li>Bisa mendapatkan hadiah langsung jika mencapai skor yang ditentukan</li>
        <li>Skor wajib ditukarkan dengan hadiah di program pesta reward</li>
        <li>Priode pesta reward berlangsung selama 120hr </li>
      </ul>
    </p>
    <h6>CARA BERMAIN</h6>
    <p>BOLA HOKI
      <ol class="pl-4">
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang</li>
        <li>Peserta yang ikut dilayanan SERBA HOKI akan dikenakan biaya mulai dari Rp1rb/pesan, 2rb/2Hari , 3rb/3hari. 5rb/minggu , 8rb/10hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token</li>
      </ol>
    </p>
    <p>BALAP HOKI
      <ol class="pl-4">
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang</li>
        <li>Peserta yang ikut dilayanan SERBA HOKI akan dikenakan biaya mulai dari Rp1rb/pesan, 2rb/2Hari , 3rb/3hari. 5rb/minggu , 8rb/10hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token</li>
      </ol>
    </p>
    <p>KETOKOO
      <ol class="pl-4">
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang</li>
        <li>Peserta yang ikut dilayanan SERBA HOKI akan dikenakan biaya mulai dari Rp1rb/pesan, 2rb/2Hari , 3rb/3hari. 5rb/minggu , 8rb/10hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token</li>
      </ol>
    </p>
    <p>ULAR TENGGO
      <ol class="pl-4">
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang</li>
        <li>Peserta yang ikut dilayanan SERBA HOKI akan dikenakan biaya mulai dari Rp1rb/pesan, 2rb/2Hari , 3rb/3hari. 5rb/minggu , 8rb/10hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token</li>
      </ol>
    </p>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Header from '@/components/Header.vue'
export default {
  name: 'Faq',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Cara Bermain",
      showToken: false,
      showBg: true,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: 'coin',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
