<template>
  <div>
    <b-row class="justify-content-center h-100 mx-0">
      <b-col align-self="center" class="p-0">
        <ul class="list-unstyled">
          <div v-for="(list, index) in lists" :key="index">
            <a :href="list.url" class="text-decoration-none">
              <b-card-body
                class="position-relative text-white mb-2 align-content-between align-self-center d-flex flex-wrap bg-store pt-2 pb-3"
                style="border-radius:15px;"
              >
                <div class="text-left">
                  <h5 class="mt-2 mb-0" style="font-size:1rem">
                    {{ list.name }}
                  </h5>
                  <span style="font-size:.8rem">{{ list.description }}</span>
                </div>
                <div class="">
                  <b-img
                    :src="require('@/assets/tropy.png')"
                    alt="Media Aside"
                    style="width:2rem;height:3rem;right:0rem"
                    class="m-2 position-absolute"
                  ></b-img>
                  <h4
                    class="mt-2 font-weight-bold pr-2 position-absolute"
                    style="right: 2.5rem;top: 1rem;"
                  >
                    +10
                  </h4>
                </div>
              </b-card-body>
            </a>
          </div>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Liststore",
  data() {
    return {
      lists: this.listStore
    };
  },
  props: ["listStore"],
  methods: {
    handleClick(id) {
      console.log(id);
      // this.$router.push({ path: `/store/${id}` })
    }
  }
};
</script>
<style scoped>
.bg-store {
  background: url("../assets/bg-service.png") center no-repeat,
    linear-gradient(167.34deg, #ff4201 25.68%, #ff0077 65.68%);
    /* background: linear-gradient(92.88deg, #F11240 4.2%, #BF0244 97.09%); */
  background-size: cover;
}
</style>
