import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { accessApi, apiPortal, responseApi, toEncrypt } from '@/provider'
// import { toEncrypt } from "../provider";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inboxFitur: true,
    redeemFitur: false,
    storeFitur: true,
    bannerFitur: true,
    isLoading: true,
    user: null,
    profile: null,
    token: null,
    url: null,
    listgame: [],
    detailgame: {},
    tokenLife: null,
    statusStore: false,
    gameId: null,
    gameIdUnix: null,
    coin: 0,
    point: 0,
    dataPoint: [],
    reward: null,
    mode: 'reg',
    // reward:[
      // {id: 1, name: 'Apple Watch',  url: require("@/assets/reward/star_applewatch.png")},
      // {id: 2, name: 'Galaxy Fit', url: require("@/assets/reward/star_galaxyfit.png")},
      // {id: 3, name: 'Iphone', url: require("@/assets/reward/star_iphone.png")},
      // {id: 4, name: 'Motor',  url: require("@/assets/reward/star_motor.png")},
      // {id: 5, name: 'Playstation',  url: require("@/assets/reward/star_playstation.png")},
      // {id: 6, name: 'Smart TV', url: require("@/assets/reward/star_smarttv.png")}
    // ],
    bannerList: [
      {
        id: 1, 
        name: 'Pesta Reward',
        image: require('@/assets/pesta-reward.png'),
        description: '<p>PESTA REWARD merupakan Layanan sms berlangganan yang memberikan puluhan reward menarik untuk Pelanggan Setia PESTA REWARD. Periode program ini berlaku selama 1 tahun. Pelanggan berkesempatan untuk memenangkan beragam Reward di PESTA REWARD Periode 6.</p> <h6>REWARD</h6> <ul> <li>Uang Tunai</li> <li>Kuota Gratis 1 tahun</li> <li>I Watch Series 5</li> <li>PS5</li> <li>Gaji Setahun</li> <li>Asus Zenbook 14</li> <li>Samsung S20 FE</li> <li>Samsung Galaxy Flip</li> <li>Samsung Galaxy Fold</li> <li>IPhone 12</li> <li>IPhone 12 Mini</li> <li>IPhone 12 Pro</li> <li>IPhone 12 Pro Max</li> <li>All New Nmax</li> </ul> <p>Pelanggan yang memiliki skor tertinggi tiap minggunya berhak untuk mendapatkan Reward! Skor diperoleh dengan cara bermain game dan renewal berlangganan.</p>',
        url: 'leaderboard',
      },
      {
        id: 2, 
        name: 'Hadiah Langsung',
        image: require('@/assets/hadiah-langsung.png'),
        description: '<p>Pelanggan yang bermain game eksklusif berkesempatan untuk memenangkan Hadiah Langsung jika mencapai skor tertentu di game eksklusif</p> <h6>Hadiah langsung:</h6> <ul> <li>SKOR 25-> Paket data 1GB</li> <li>SKOR 26-> Plisa Rp.100.000</li> <li>SKOR 27-> Uang Tunai Rp.500.000</li> <li>SKOR 28-> Uang Tunai Rp.1.000.000</li> <li>SKOR 29-> Uang Tunai Rp.1.500.000</li> <li>SKOR 30-> Uang Tunai Rp.2.000.000</li></ul>',
        url: 'home',
      },
      {
        id: 3, 
        name: 'Hadiah Langsung',
        image: require('@/assets/redeem.png'),
        description: '<p>Pelanggan dapat menukarkan skor yang diperoleh dengan Pulsa dan Kuota di page Redeem!</p>',
        url: 'redeem',
      },
    ],
    leaderboardList:[
      // {
      //   msisdn: "62812345xxxx",
      //   point: "10000"
      // },
      // {
      //   msisdn: "62812111xxxx",
      //   point: "9000"
      // },
      // {
      //   msisdn: "62822443xxxx",
      //   point: "8000"
      // },
      // {
      //   msisdn: "62812645xxxx",
      //   point: "7000"
      // },
      // {
      //   msisdn: "62822349xxxx",
      //   point: "6000"
      // },
      // {
      //   msisdn: "62822340xxxx",
      //   point: "5000"
      // },
      // {
      //   msisdn: "62811340xxxx",
      //   point: "4500"
      // },
      // {
      //   msisdn: "62812040xxxx",
      //   point: "3000"
      // },
      // {
      //   msisdn: "62822390xxxx",
      //   point: "2500"
      // },
      // {
      //   msisdn: "62811140xxxx",
      //   point: "1000"
      // },
    ],
    popupReward: [
      {
        point: "25",
        image: require("@/assets/hadiah-1gb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung paket Data 1GB! Ambil hadiahnya sekarang!"
      },
      {
        point: "26",
        image: require("@/assets/hadiah-100rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 100 ribu! Ambil hadiahnya sekarang!"
      },
      {
        point: "27",
        image: require("@/assets/hadiah-500rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 500 ribu! Ambil hadiahnya sekarang!"
      },
      {
        point: "28",
        image: require("@/assets/hadiah-1jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 1 juta! Ambil hadiahnya sekarang!"
      },
      {
        point: "29",
        image: require("@/assets/hadiah-1.5jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 1,5 juta! Ambil hadiahnya sekarang!"
      },
      {
        point: "30",
        image: require("@/assets/hadiah-2jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 2 juta! Ambil hadiahnya sekarang!"
      }
    ],
    redeemList: [
      {
        id: 1,
        name: "Gratis Pulsa 5 Ribu",
        image: require("@/assets/redeem-5k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 2,
        name: "Gratis Pulsa 10 Ribu",
        image: require("@/assets/redeem-10k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 3,
        name: "Gratis Pulsa 25 Ribu",
        image: require("@/assets/redeem-25k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      }
    ],
    inboxList: [
      {
        id: 1,
        name: "Info",
        image: '',
        created: "2021/05/01",
        description: "Selamat Datang di 3ple Hoki game. Mainkan terus gamenya dan dapatkan banyak hadiah menarik untuk kamu",
        url: "#"
      },
      {
        id: 2,
        name: "Info",
        image: require("@/assets/redeem-10k.png"),
        created: "2021/05/01",
        description: "3ple Hoki adalah portal game yang menyediakan banyak hadiah menarik. Untuk memulai bermain kamu harus menggunakan token.",
        url: "#"
      },
    ],
    storeList: [
      {
        id: 1,
        name: "Berlangganan per hari",
        image: null,
        price: 1100,
        description: "Rp.1100/sms/hari",
        url: "sms:92229?&body=REG BALAP5 3PLEHOKI"
      }
    ],
    popupToken: {
      point: null,
      image: require("@/assets/token-null.png"),
      title: "Token Tidak Cukup!",
      description:
        "Yaah, tokenmu tidak cukup untuk main game ini. Tambah dulu yuk tokenmu!"
      // description: "Token kamu tidak ada, kamu bisa bermain lagi diesok hari!"
    },
    appKey: 'eyJhcHBfYWxpYXMiOiIzcGxlaG9raSIsImFwcF9uYW1lIjoiM3BsZSBIb2tpIiwiY3BfaWQiOjV97H8ovuaqiBHzDl3OkpUUM3qP',
    secretKey: 'Bismillah',
    telco: null,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    isUser: state => state.user,
    getUser: state => state.user,
    getSecret: state => state.secretKey,
    getGameId: state => state.gameId,
    getLifeId: state => state.tokenLife,
    getCoin: state => state.coin,
    getMode: state => state.mode,
    getRedeem: state => state.redeemList,
    getStore: state => state.storeList,
    getBanner: state => state.bannerList,
    getInbox: state => state.inboxList,
    getReward: state => state.popupReward,
    getLeaderboard: state => state.leaderboardList,
    getGameIdUnix: state => state.gameIdUnix,
    getAppkey: state => state.appKey,
  },
  mutations: {
    SET_TOKEN: (state, value) => value ? (state.token = value) : (state.token = null),
    SET_USER: (state, value) => value ? (state.user = value) : (state.user = null),
    SET_URL: (state, value) => value ? (state.url = value) : (state.url = null),
    SET_LIST_GAME: (state, value) => value ? (state.listgame = value) : (state.listgame = []), 
    SET_PROFILE: (state, value) => value ? (state.profile = value) : (state.profile = null),
    SET_TOKEN_LIFE: (state, value) => value ? (state.tokenLife = value) : (state.tokenLife = null),
    SET_DATA_POINT: (state, value) => value ? (state.dataPoint = value) : (state.dataPoint = []),
    SET_GAME_ID: (state, value) => value ? (state.gameId = value) : (state.gameId = null),
    SET_DETAIL_GAME: (state, value) => value ? (state.detailgame = value) : (state.detailgame = {}),
    SET_COIN: (state, value) => value ? (state.coin = value) : (state.coin = 0),
    SET_POINT: (state, value) => value ? (state.point = value) : (state.point = 0),
    SET_REWARD: (state, value) => value ? (state.reward = value) : (state.reward = null),
    SET_MODE: (state, value) => value ? (state.mode = value) : (state.mode = 'free'),
    SET_TELCO: (state, value) => value ? (state.telco = value) : (state.telco = null),
    SET_BANNER_LIST: (state, value) => value ? (state.bannerList = value) : (state.bannerList = []),
    SET_REWARD_LIST: (state, value) => value ? (state.popupReward = value) : (state.popupReward = []),
    SET_STORE_LIST: (state, value) => value ? (state.storeList = value) : (state.storeList = []),
    SET_LEADERBOARD_LIST: (state, value) => value ? (state.leaderboardList = value) : (state.leaderboardList = []),
    SET_INBOX_LIST: (state, value) => value ? (state.inboxList = value) : (state.inboxList = []),
    SET_GAME_ID_UNIX: (state, value) => value ? (state.gameIdUnix = value) : (state.gameIdUnix = null),
  },
  actions: {
    getProfile: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameProfile', sendData, { headers })
          const dataProfile = await JSON.parse(responseApi(data))
          const JsonDataProfile = dataProfile
          commit('SET_TELCO', JsonDataProfile.telco)
          commit('SET_PROFILE', JsonDataProfile)
          // console.log('SET_TELCO', JsonDataProfile.telco)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDetail: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameDetail', sendData, { headers })
          const dataDetail = await JSON.parse(responseApi(data))
          const JsonDataDetail = dataDetail
          // console.log(JsonDataDetail)
          // commit('SET_COIN', 1)
          commit('SET_COIN', JsonDataDetail.token)
          if (getters.getMode === 'free') {
            JsonDataDetail.point = getters.getPoint
            commit('SET_POINT', JsonDataDetail.point)
          } else {
            commit('SET_POINT', JsonDataDetail.point)
          }
          
          // console.log(process.env.VUE_APP_KEY, process.env.VUE_APP_SECRET);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGames: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('games', { headers })
        const dataGames = JSON.parse(responseApi(data))
        // let newData = dataGames.filter( item => {
        //   return item.id === '4271230733' || item.id === '1685614594' || item.id === '1449509162' || item.id === '4260850995'
        // })
        commit('SET_LIST_GAME', dataGames)
      })
    },
    getLeaderboard: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
        const sendData = { req: msisdn }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameLeaderboard', sendData, { headers })
        const dataLeaderboard = JSON.parse(responseApi(data))
        // console.log(dataLeaderboard);
        commit('SET_LEADERBOARD_LIST', dataLeaderboard)
      })
    },
    getInbox: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 5 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_INBOX _LIST', dataGames)
      })
    },
    getBanner: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 4 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log('data banner',dataGames);
        commit('SET_BANNER_LIST', dataGames)
      })
    },
    getRedeem: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REDEEM_LIST', dataGames)
      })
    },
    getStore: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 2 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_STORE_LIST', dataGames)
      })
    },
    getReward: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 1 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REWARD_LIST', dataGames)
      })
    },
    getGameReward: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('gameReward', { headers })
        const res = responseApi(data)
        const dataReward = JSON.parse(res)
        // console.log(dataReward);
        commit('SET_REWARD', dataReward)
      })
    },
    getLife: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const unix = Math.round(+new Date()/1000);
          const unixSlice = unix.toString().slice(1, 10);
          const gameUnix = getters.getGameId+parseInt(unixSlice) 
          const datas = {
            game_id: gameUnix,
            msisdn: getters.getUser,
            type: "portal",
          }
          // console.log('data dikirim', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret) 
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameLife', sendData, { headers })
          const dataLife = await JSON.parse(responseApi(data))
          commit('SET_TOKEN_LIFE', dataLife.token_life)
          commit('SET_GAME_ID_UNIX', gameUnix)
          // console.log('getlife',dataLife);
          resolve(dataLife.token_life);
        }).catch(error => {
          reject(error)
        })
      })
    },
    storePoint: async ({commit, getters}, point) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = {
            game_id: getters.getGameIdUnix,
            msisdn: getters.getUser,
            token_id: getters.getLifeId,
            data: point,
          }
          // console.log('store data', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret)
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gamePoint', sendData, { headers })
          const dataPoint = await JSON.parse(responseApi(data))
          // console.log('response store', dataPoint)
          commit('SET_DATA_POINT', dataPoint)
          resolve(dataPoint)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {
    // login
  }
});
