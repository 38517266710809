<template>
  <section>
    <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
    <div class="banner-image" :style="`background:url(${detailgame.image}) no-repeat`">

    </div>
    <div class="home px-3 text-left">
      <h5 class="mb-2 mt-3 font-weight-bold">{{detailgame.game_name}}</h5>
      <div class="mb-2 wrapper">
        <Flicking :options="{ moveType: 'freeScroll', bound: true, align: 'prev' }">
          <div class="button is-red px-3 py-1 mx-1 my-2 b-r-50" v-if="detailgame.id == 4260850995">
            <b-img
              :src="require('@/assets/star.png')"
              fluid
              rounded
              alt="Responsive image"
              class=""
            ></b-img> Eksklusif
          </div>
          <div class="button is-blue px-3 py-1 mx-1 my-2 b-r-50" v-if="detailgame.id == 4260850995">
            <b-img
              :src="require('@/assets/icon-hadiah.png')"
              fluid
              rounded
              alt="Responsive image"
              class=""
            ></b-img> Hadiah Langsung
          </div>
          <div class="button is-yellow px-3 py-1 mx-1 my-2 b-r-50">
            <b-img
              :src="require('@/assets/icon-reward.png')"
              fluid
              rounded
              alt="Responsive image"
              class=""
            ></b-img> Pesta Reward
          </div>
        </Flicking>
      </div>
      <p>{{detailgame.description}}</p>
      <b-button
        type="button"
        block
        variant="warning"
        class="max-35 rounded-pill btn-lg mb-4"
        @click="handleClick(detailgame.link)"
        >Main Sekarang</b-button
      >
    </div>
    <Popup :ids="idToken" :dataPopup="dataToken" @addCoin ="updatePoin" />
    <Popup :ids="idReward" :dataPopup="dataReward" />
  </section>
</template>

<script>
// @ is an alias to /src
// import Gallery from "@/components/Gallery.vue";
// import Point from "@/components/Point.vue";
// import ListLeaderboard from "@/components/ListLeaderboard.vue";
// import 'swiper/swiper-bundle.css'
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'

import { mapMutations, mapState } from 'vuex'
import router from '@/router'
import Header from '@/components/Header.vue'
import Popup from '@/components/Popup.vue'
export default {
  name: "Home",
  components: {
    Header,
    Popup,
  },
  mixins: [ Popup ],
  data() {
    return {
      titleHeader: "",
      showToken: true,
      showBg: false,
      totalToken: null,
      isShow: false,
      idReward: 'popupReward',
      idToken: 'popupToken',
      dataReward: {
        image: null,
        title: null,
        description: null,
        txtButton: null
      },
      dataToken: {
        image: null,
        title: null,
        description: null,
        txtButton: null
      },
      // plugins: [)]
    }
  },
  mounted() {
    this.setGameId(this.$route.params.id)
    if(this.listgame.length !== 0) {
      this.getDetail(this.gameId)
      // console.log('game_id',this.gameId);
    } else {
      router.push({ name : 'home'})
    }
  },
  computed: {
    ...mapState([
      'gameId',
      'url',
      'reward',
      'token',
      'user',
      'listgame',
      'detailgame',
      'coin',
      'popupToken',
      'mode',
    ])
  },
  methods: {
    ...mapMutations({
      setGameId: 'SET_GAME_ID',
      setDetailGame: 'SET_DETAIL_GAME',
      setUrl: 'SET_URL',
      // setReward: 'SET_REWARD',
    }),
    handleClick(url) {
      const sUrl = url.replace(/^http:\/\//i, 'https://');
      // const sUrl = url

      this.setUrl(sUrl+'?msisdn='+this.user)

      if(this.coin === 0 || this.coin === null){
        if (this.mode === 'free') {
          router.push({ name: "play" });
        } else {
          this.showPopupValidateToken()
        }
      } else {
        router.push({ name: "play" });
      }
    },
    getDetail(id) {
      const gId = parseInt(id)
      const games = this.listgame
      const index = games.findIndex(item => item.id === gId)
      this.setDetailGame(games[index])
    },
    goTo(page) {
      router.push({ name: page });
    },
    showPopupValidateToken() {
      this.setPopupToken()
      this.showPopup(this.idToken)
    },
    showPopupReward() {
      this.setPopupReward('26')
      this.showPopup(this.idReward)
    },
    setPopupToken() {
      const token = this.popupToken;
      this.dataToken.image = token.image;
      this.dataToken.title = token.title;
      this.dataToken.description = token.description;
      this.dataToken.txtButton = "Tambah";
    },
    setPopupReward(point) {
        const index = this.reward.findIndex(
        item => item.point === point
      );
      const rewards = this.reward[index];
      this.dataReward.image = rewards.image;
      this.dataReward.title = rewards.title;
      this.dataReward.description = rewards.description;
      this.dataReward.txtButton = "Ambil";
    },
    updatePoin() {
      this.totalCoin = 1
    }
  }
};
</script>

<style scoped>
body {
  background-color: white;
}
.banner-image {
  height: 25rem;
  background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%);
  /* background-color: #FECF2F; */
    background-size: cover !important;
    background-position: center !important;
}
.is-white {
  background: white;
}
.is-red {
  background: linear-gradient(90deg, #FF4401 0%, #FF0077 57.29%);
  color: white;
}
.is-blue {
  background: #692B8E;
  color: white;
}
.is-yellow {
  background: #FF9B00;
  color: white;
}
.b-r-50 {
  border-radius: 50px;
}
/* .home {
  margin-top: 5rem;
} */
</style>
