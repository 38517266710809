<template>
<div class="content-login h-100">
    <b-container fluid class="h-100 px-0">
      <Nav />
      <transition :name="transitionName">
      <router-view />
      </transition>
      <div class="footer">
          <!-- <span>Powered by </span>
          <b-img :src="require('@/assets/tsel.png')" alt="Media Aside" style="width:5rem;"></b-img><br> -->
        <span style="font-size:.7rem">Copyright by 3PLEHOKI</span>
      </div>
    </b-container>
</div>
    
</template>
<script>
import Nav from "@/components/Nav.vue";
export default {
    data() {
    return {
      transitionName: ""
    }
  },
    components: {
        Nav
    },
    // watch: {
    //     $route(to, from) {
    //     this.transitionName = to.meta.page > from.meta.page ? "next" : "prev";
    //     }
    // }
    
}
</script>
<style scoped>
    .content-login{
        background-color: #F7F7FC;
        background-image: url('../assets/bg-portal.png');
        background-position: center;
        background-size: contain;
        min-height: 100vh;
    }
    .footer{
        position: absolute;
        width: 100%;
        padding: 13px;
        /* background: chartreuse; */
        left: 0px;
    }
</style>