<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <h3></h3>
    <p class="text-left" style="font-size:.9rem">
      <ol class="pl-4">
        <li>Program 3PLE HOKI disediakan untuk Seluruh Pelanggan setia HUTCHINSON TRI</li>
        <li>Program 3PLE HOKI diawasi dan diputuskan oleh Juri yang beranggotakan Pihak Penyelenggara</li>
        <li>Pelanggan yang terdaftar dan aktif dilayanan/ keyword 3PLE HOKI  akan mendapatkan kesempatan untuk bermain game di Portal 3PLE Hoki dan bisa mendapatkan reward menarik</li>
        <li>Terdapat 9 pilihan game yang bisa dimainkan</li>
        <li>Pelanggan yang bermain GAME memiliki keuntungan dapat memenangkan REWARD LANGSUNG</li>
        <li>Untuk mendapatkan REWARD LANGSUNG berupa PAKET DATA,PULSA DAN GOPAY, pelanggan harus mengumpulkan skor untuk Reward Langsung , perhitungan skor sbb:
          <ul class="pl-3">
            <li>Pelanggan yang mencapai 25 skor akan mendapatkan reward 1 GB data</li>
            <li>Pelanggan yang mencapai 26 Skor akan mendapatkan pulsa RP100.000</li>
            <li>Pelanggan yang mencapai 27 Skor akan mendapatlan GOPAY Rp500.000</li>
            <li>Pelanggan yang mencapai 28 Skor akan mendapatkan GOPAY Rp1.000.000</li>
            <li>Pelanggan yang mencapai 29 Skor akan mendapatkan GOPAY Rp1.500.000</li>
            <li>Pelanggan yang mencapai 30 Skor akan mendapatkan GOPAY Rp2.000.000</li>
          </ul>
        </li>
        <li>Semua perolehan SKOR game terakumulasi didalam SKOR PESTA REWARD yang ditentukan dengan Skor tertinggi selama periode berlangsung.</li>
        <li>Para Pemenang akan dihubungi langsung oleh pihak Customer Service AMAN DEVLOOP INDONESIA untuk verifikasi data</li>
        <li>Penukaran SKOR bersifat WAJIB untuk Peserta/ Pemenang</li>
        <li>Peserta yang ikut dilayanan 3PLE HOKI akan dikenakan biaya mulai dari Rp1rb/hari</li>
        <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan 1 token dan 10 skor</li>
        <li>Pelanggan yang menang dan akan meredeem SKORnya wajib mengirimkan Data-data administrasi antara lain:
          <ol class="pl-3">
            <li>Fotocopy kartu identitas diri yang masih berlaku (KTP/SIM/KartuPelajar)</li>
            <li>Nomor MSISDN/HP sesuai dengan yang telah didaftar dalam database Penyelenggara dan masih aktif mengikuti/ terdaftar dilayanan 3PLE HOKI.</li>
            <li>Data-data administrasi dapat dikirim via email ke alamat cs@develoop.id</li>
          </ol>
        </li>
        <li>Jika dalam jangka waktu 1 (satu) minggu setelah tanggal pengumuman Pelanggan dengan SKOR tertinggi dan tercepat tidak mengirimkan data-data kelengkapan administrasi yang diminta oleh Penyelenggara, maka reward dapat dibatalkan</li>
        <li>Layanan 3PLE HOKI Periode 6 berlangsung selama 4 bulan dan di perpanjang setiap periode dan berlanjut tanpa batasan waktu.</li>
        <li>Ini BUKAN PROGRAM UNDIAN</li>
        <li>Tidak mengandung unsur layanan untung-untungan</li>
        <li>Ketentuan atas layanan ini tidak bersifat final, segala perubahan terhadap syarat & ketentuan adalah Hak Juri</li>
        <li>Reward sewaktu-waktu dapat berubah tanpa ada pemberitahuan sebelumnya. Jika reward tidak tersedia, misal stok produk dipasaran habis dan atau sudah discontinue, maka Pihak Penyelenggara berhak untuk menggantkan dengan reward lainnya yang sejenis dana tau senilai dengan reward yang seharusnya diterima oleh Pemenang. Atau menggantikannya dengan Uang Tunai, namun hal ini merupakan opsi dari Pihak Penyelenggara dan BUKAN pilihan dari Pemenang atau Peserta</li>
        <li>Info lebih lanjut bisa menghubungi Customer Service kami di:
          <ul>
            <li>021-22732014</li>
            <li>cs@develoop.id</li>
          </ul>
        </li>
      </ol>
      </p>
      <!-- <p class="text-left" style="font-size:.9rem">
        <b>Mekanisme program hadiah</b>
        <ol class="pl-4">
          <li>Saat pelanggan menyetujui untuk mengikuti Program 3PLE HOKI maka pelanggan akan dikenakan biaya Rp.1100/sms/hari, Rp.2200/sms/2hari, Rp.3300/sms/3hari, Rp.5500/sms/minggu, Rp.8800/sms/10hari (incl PPn) dari SDC 94449 </li>
          <li>Sms berlangganan akan dikirimkan ke pelanggan dengan dikenakan harga sebesar Rp.1100/sms/hari, Rp.2200/sms/2hari, Rp.3300/sms/3hari, Rp.5500/sms/minggu, Rp.8800/sms/10hari (incl PPn)</li>
          <li>Pelanggan akan menerima 1 token setiap hari selama berlangganan </li>
          <li>Untuk mendapatkan reward Langsung berupa PAKET DATA,PULSA DAN GOPAY, pelanggan harus mengumpulkan skor yang telah ditentukan 
            <ul class="pl-3">
              <li>Pelanggan yang mencapai 25 skort akan mendapatkan reward 1 GB data </li>
              <li>Pelanggan yang mencapai 26 Skor akan mendapatkan pulsa Rp. 100.000 </li>
              <li>Pelanggan yang mencapai 27 Skor akan mendapatlan GOPAY Rp. 500.000 </li>
              <li>Pelanggan yang mencapai 28 Skor akan mendapatkan GOPAY Rp. 1.000.000 </li>
              <li>Pelanggan yang mencapai 29 Skor akan mendapatkan GOPAY Rp. 1.500.000 </li>
              <li>Pelanggan yang mencapai 30 Skor akan mendapatkan GOPAY Rp. 2.000.000 </li>
            </ul>
          </li>
          <li>Untuk berhenti dari layanan sms berlangganan ini, Pelanggan ketik STOP atau: 
            <ul>
              <li>UNREG PORTAL1 </li>
              <li>UNREG PORTAL2 </li>
              <li>UNREG PORTAL3 </li>
              <li>UNREG PORTAL4 </li>
              <li>UNREG PORTAL5 </li>
            </ul>
            ke 94449
          </li>
        </ol>
      </p> -->
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Header from '@/components/Header.vue'
export default {
  name: 'Tnc',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Syarat & Ketentuan",
      showToken: false,
      showBg: true,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: 'coin',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
