<template>
  <div class="h-100" style="min-height:100vh;">
    <b-row class="justify-content-center h-100 mx-0" style="min-height:100vh;">
      <b-col class="px-0">
        <fullscreen ref="fullscreen" @change="fullscreenChange" style="height:100vh;">
          <!-- <b-aspect aspect="16:9"> -->
          <iframe
            ref="iframe"
            :src="url"
            width="100%"
            height="100%"
            class="display-frame"
            frameborder="0"
          >
          </iframe>
          <div class="fixed-bottom text-left mx-3 my-2">
            <b-button @click="toggle" class="custom-btn">
              <b-icon
                :icon="!fullscreen ? 'fullscreen' : 'fullscreen-exit'"
                size="3rem"
                style="color: red;"
              ></b-icon>
            </b-button>
          </div>
          <Popup :ids="idToken" :dataPopup="dataToken" @addCoin ="updatePoin" />
          <Popup :ids="idReward" :dataPopup="dataReward" />
        </fullscreen>

        <!-- <b-button type="button" @click="toggle" variant="light" class="mt-3">View Fullscreen</b-button> -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
import fullscreen from "vue-fullscreen";
import Vue from "vue";
// import { apiPortal, response } from '@/provider'
import Popup from '@/components/Popup.vue'
import { mapActions, mapState } from 'vuex';
Vue.use(fullscreen);
export default {
  components: {
    Popup,
  },
   mixins: [ Popup ],
  data() {
    return {
      // url: this.$store.state.url,
      fullscreen: false,
      iframeGame: {},
      storeData: {
        game_id: this.gameId,
        msisdn: this.$store.state.user,
        token_id: this.$store.state.tokenLife,
        data: [],
      },
      loadAjax: false,
      idReward: 'popupReward',
      idToken: 'popupToken',
      // reward: this.$store.state.dataReward,
      dataReward: {
        image: null,
        title: null,
        description: null,
        txtButton: null
      },
      // token: this.$store.state.dataToken,
      dataToken: {
        image: null,
        title: null,
        description: null,
        txtButton: null
      },
      // profile: null,
    };
  },
  computed: {
    ...mapState([
      'gameId',
      'user',
      'tokenLife',
      'popupReward',
      'popupToken',
      'url',
      'reward',
      'coin',
      'profile',
      'mode',
    ])
  },
  beforeMount() {
    this.$store.state.isLoading = true
  },
  mounted() {
      // this.$store.commit("getTokenLife", this.appKey)
      // Promise.resolve(() => {
            // this.getProfile().then(() => {
            //   console.log('cetak data', this.profile);
            // })
          //   console.log('akses get profile');
          // }).then(() => {
          //   const params = {
          //     success: true,
          //     data: this.profile,
          //     reward: this.reward,
          //   }
          //   this.accessMethodeGame('setProfile', params);
          // })
    // console.log(this.$store.state.reward);
    window.addEventListener("message", event => {
      const data = event.data;
      switch (data.cmd) {
        case "popupHadiah":
          this.showPopupReward(data.param.data.point);
          break;
        case "popupToken":
          this.showPopupValidateToken();
          break;
        case "getProfile":
          this.getDetail().then(() => {
            // console.log('profile', this.$store.state.profile);
            const dataPoint = this.$store.state.point
            const dataLife = this.$store.state.coin
            const dataTelco = this.$store.state.telco
            // console.log('telco', dataTelco);
            const params = {
              success: true,
              data: JSON.stringify({ point: dataPoint, life: dataLife, dificult: 1, avatar: null, telco: {mno_shortname: dataTelco} }),
              reward: this.reward,
              type: 0,
              options: {
                title_value: 'score'
              }
            }
            this.$store.state.isLoading = false
            this.accessMethodeGame('setProfile', params);
          }, error => {
            console.error('err', error);
          })
          break;
        case "getDetail":
          this.getDetail().then(() => {
            const dataPoint = this.$store.state.point
            const dataLife = this.$store.state.coin
            const params = {
              success: true,
              data: { poin : dataPoint, life : dataLife, difficult: 1}
            }
            this.accessMethodeGame('setDetail', params);
          }, error => {
            console.error('err', error);
          })
          break;
        case "getTokenLife": 
          this.getLife().then(() => {
            const params = {
              success: true,
              data: {},
            }
            if (this.tokenLife === null || this.coin === 0) {
              if (this.mode === 'free') {
                this.accessMethodeGame('setTokenLife', params);
              } else {
                this.showPopupValidateToken();
              }
            } else {
              this.accessMethodeGame('setTokenLife', params); 
            }
          }, error => {
            console.error('err', error);
          })
          break;
        case "storePoint": {
          let dataPoint = data.param.data.point
          let totalPoint = dataPoint[0]
           console.log('data',totalPoint);
          if (this.mode !== 'free') {
            // this.store(data.param.data.point)
            this.storePoint(dataPoint).then(() => {
              const params = {
                success: true,
                data: {},
              }
              if (parseInt(this.gameId.toString()) === 4260850995) {
                this.showPopupReward(totalPoint)
              }
              this.accessMethodeGame('statusStorePoint', params);
            }, error => {
              console.error('err', error);
              const params = {
                success: false,
                data: {},
              }
              this.accessMethodeGame('statusStorePoint', params);
            })
          } else {
            this.$store.commit('SET_POINT', totalPoint)
            const params = {
              success: true,
              data: {},
            }
            // console.log(dataPoint, totalPoint);
            this.showPopupReward(totalPoint)
            this.accessMethodeGame('statusStorePoint', params);
          }
          break;
        }
      }
    });
    this.iframeGame = this.$refs.iframe.contentWindow;
  },
  methods: {
    ...mapActions([
      'getProfile',
      'getLife',
      'storePoint',
      'getDetail'
    ]),
    toggle() {
      this.$refs["fullscreen"].toggle(); // recommended
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
     showPopupValidateToken() {
      this.setPopupToken()
      // this.showPopup(this.idToken)
    },
    showPopupReward(point) {
      this.setPopupReward(point)
      // this.showPopup(this.idReward)
    },
    setPopupToken() {
      const token = this.popupToken;
      this.dataToken.image = token.image;
      this.dataToken.title = token.title;
      this.dataToken.description = token.description;
      this.dataToken.txtButton = "Tambah Token";
      this.showPopup(this.idToken)
      this.fullscreen=true;
      this.toggle();
    },
    setPopupReward(point) {
        const index = this.popupReward.findIndex(
        item => item.point === point.toString()
      );
      if (index !== -1) {
          const rewards = this.popupReward[index];
          // console.log(point, index, this.popupReward);
          this.dataReward.image = rewards.image;
          this.dataReward.title = rewards.title;
          this.dataReward.description = rewards.description;
          this.dataReward.txtButton = "Ambil";
          this.showPopup(this.idReward)
          this.fullscreen=true;
          this.toggle();
        }
    },
    // store(point) {
    //   // const sendData = {
    //   //       game_id: this.gameId,
    //   //       msisdn: this.user,
    //   //       token_id: this.tokenLife,
    //   //       data: point,
    //   //     }
    //   // console.log(sendData);
    //   this.storePoint(point).then(() => {
    //     const params = {
    //       success: true,
    //       data: {},
    //     }
    //     this.accessMethodeGame('statusStorePoint', params);
    //   })
    // },
    accessMethodeGame(command, params) {
      this.iframeGame.postMessage(
        {
          cmd: command,
          param: params,
        },
        "*"
      );
    },
    updatePoin() {
      // this.totalCoin = 1
      console.log('update coin');
    }
  }
};
</script>
<style>
@media (orientation: portrait) {
  /* #app {
    transform: rotate(90deg);
    transform-origin: top left;
    width: 100vh;
    height: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 100%;
  }
  body {
      overflow: hidden;
  } */
}
</style>
<style scoped>
/deep/ .modal-footer button {
  display: block;
  width: 100%;
  color: #f8f9fa !important;
  border-radius: 20px;
  background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%);
}
.display-frame {
  height:calc(100vh - 0px);
  width:calc(100vw - 0px);
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  top: 0px;
}
.custom-btn {
  background-color: transparent;
  padding: 0px;
  border: none;
}
@media (orientation: landscape) {
  /deep/ .modal-dialog {
    width: 50vh !important;
    margin: 0 auto !important;
  }
  .modal-body {
    padding-bottom: 0px;
  }
  .txt-desc {
    font-size: 0.8rem;
  }
}

@media (orientation: portrait) {
  .modal-dialog {
    width: 50vw !important;
    margin: 0 auto !important;
  }
  .txt-desc {
    font-size: 0.8rem;
  }
}
/* .modal-dialog {
  width: 75% !important;
  margin: 0 auto !important;
} */
</style>
