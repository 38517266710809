import Vue from "vue";
import axios from "axios";
import store from "@/store/index.js";
import CryptoJS from "vue-cryptojs";

Vue.use(CryptoJS);

export const apiPortal = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: "http://local.coregame.id/index.php/api/v1/"
  baseURL: 'https://coregame.ina17.com/api/v1/',
  // baseURL: 'https://stgcoregamecms.ina17.com/api/v1/',
  // timeout: 1000,
  // headers: { Authorization: `Bearer ${store.state.datas.token}` },
});

export const accessApi = async () => {
  const sendData = {
    msisdn: store.state.user
  };
  const headers = {
    Authorization: `bearer ${store.state.appKey}`
  }
  const { data } = await apiPortal.post("arcade/tokenGen", sendData, {headers});
  return data.single_token;
};

export const response = payload => {
  const res = payload;
  const result = atob(
    res
      .split("")
      .reverse()
      .join("")
  );
  return result;
};

export const responseApi = payload => {
  const res = payload;
  // console.log(res.payload, store.state.secretKey);
  var decr = toDecrypt(res.payload, store.state.secretKey);
  return decr;
};

export const toEncrypt = (string, key) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = parseInt(encryptMethod.match(/\d+/)[0]);
  var iv = Vue.CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

  var salt = Vue.CryptoJS.lib.WordArray.random(256);
  var iterations = 999;
  var encryptMethodLength = aesNumber / 4; // example: AES number is 256 / 4 = 64
  var hashKey = Vue.CryptoJS.PBKDF2(key, salt, {
    hasher: Vue.CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations
  });

  var encrypted = Vue.CryptoJS.AES.encrypt(string, hashKey, {
    mode: Vue.CryptoJS.mode.CBC,
    iv: iv
  });
  var encryptedString = Vue.CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

  var output = {
    value: encryptedString,
    iv: Vue.CryptoJS.enc.Hex.stringify(iv),
    salt: Vue.CryptoJS.enc.Hex.stringify(salt),
    iterations: iterations
  };

  return Vue.CryptoJS.enc.Base64.stringify(
    Vue.CryptoJS.enc.Utf8.parse(JSON.stringify(output))
  );
};

export const toDecrypt = (encryptedString, key) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = parseInt(encryptMethod.match(/\d+/)[0]);
  var json = JSON.parse(
    Vue.CryptoJS.enc.Utf8.stringify(
      Vue.CryptoJS.enc.Base64.parse(encryptedString)
    )
  );

  var salt = Vue.CryptoJS.enc.Hex.parse(json.salt);
  var iv = Vue.CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.value; // no need to base64 decode.

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = aesNumber / 4; // example: AES number is 256 / 4 = 64
  var hashKey = Vue.CryptoJS.PBKDF2(key, salt, {
    hasher: Vue.CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations
  });

  var decrypted = Vue.CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: Vue.CryptoJS.mode.CBC,
    iv: iv
  });
// console.log(Vue.CryptoJS.enc.Utf8.parse(decrypted).toString());
  return decrypted.toString(Vue.CryptoJS.enc.Utf8);
};
