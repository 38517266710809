<template>
  <section>
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <div v-if="!isLoading"> -->
      <div class="header-home shadow-sm">
        <b-row class="mx-0">
          <b-col class="sm-4 px-0"> </b-col>
          <b-col class="sm-4 px-0">
            <img
              alt="Vue logo"
              src="@/assets/logo.png"
              class="mt-2"
              style="width: 6rem"
            />
          </b-col>
          <b-col class="sm-4 px-0">
            <!-- <div class="mt-2 mr-3">
              <b-media right-align vertical-align="center" class="ml-0">
                <template #aside>
                  <img
                    alt="Vue logo"
                    src="@/assets/icon-token.png"
                    class="mt-1"
                    style="width: 2.5rem; height: 2.5rem"
                  />
                </template>
                <h4 class="mt-3 mb-1 font-weight-bold text-light text-right">
                  {{ coin !== null ? coin : 0 }}
                </h4>
              </b-media>
            </div> -->
          </b-col>
        </b-row>
      </div>
      <div class="home px-3">
        <!-- <h3 class="text-center font-weight-bold">ubgame.id</h3> -->
        <b-card-body class="card-body mb-3 p-0 roun shadow d-flex" style="border-radius:24px;background:linear-gradient(92.88deg, #F11240 4.2%, #BF0244 97.09%); border: #FED532 3px solid;">
          <div class="w-50 text-white">
            <b-media
              no-body
              tag="li"
              class="px-2 my-2 border-right"
              style="position:relative;"
            >
              <b-media-aside class="align-self-cente mr-1">
                <b-img
                  :src="require('@/assets/coin.png')"
                  alt="Media Aside"
                  style="width:3rem;height:3rem;"
                  class="m-2"
                ></b-img>
              </b-media-aside>
              <b-media-body class="align-content-between align-self-center d-flex flex-wrap">
                  <div>
                    <h6 class="mt-2 mb-0" style="text-align:left;font-size:.8rem">
                      Skor
                    </h6>
                    <h5 class="font-weight-bold" style="right: 1rem;">{{ point !== null ? point : 0 }}</h5>

                  </div>
              </b-media-body>
            </b-media>
          </div>
          <div class="w-50 text-white">
            <b-media
              no-body
              tag="li"
              class="px-2 my-2 border-left"
              style="position:relative;"
            >
              <b-media-aside class="align-self-cente mr-1">
                <b-img
                  :src="require('@/assets/token.png')"
                  alt="Media Aside"
                  style="width:3rem;height:3rem;"
                  class="m-2"
                ></b-img>
              </b-media-aside>
              <b-media-body class="align-content-between align-self-center d-flex flex-wrap">
                  <div>
                  <h6 class="mt-2 mb-0" style="text-align:left;font-size:.8rem">
                      Token
                    </h6>
                    <h5 class="font-weight-bold" style="right: 1rem;">{{ coin !== null ? coin : 0 }}</h5>
                  </div>
              </b-media-body>
            </b-media>
          </div>
        </b-card-body>
        <div class="">
          <div class="mb-3 wrapper" v-if="bannerFitur">
          <swiper ref="promo" :options="swiperOptions">
            <swiper-slide v-for="(banner, index) in bannerList" :key="index">
                <b-img
                  :src="banner.image"
                  fluid
                  rounded
                  class="w-100 px-2"
                  alt="Responsive image"
                  @click="handleClick(banner.id)"
                ></b-img>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- <Flicking :options="{ moveType: 'freeScroll', bound: true, align: 'prev' }">
            <div v-for="(banner, index) in bannerList" :key="index">
              <b-img
                  :src="banner.image"
                  fluid
                  rounded
                  class="w-100 px-2"
                  alt="Responsive image"
                  @click="handleClick(banner.id)"
                ></b-img>
            </div>
          </Flicking> -->
        </div>
        <div class="">
          <b-img
          :src="require('@/assets/bg-header.png')"
          fluid
          rounded
          alt="Responsive image"
          class="mb-3"
        ></b-img>
        <ListLeaderboard :listBoard="dataLeaderboard" />
        <b-button
          type="button"
          block
          variant="success"
          class="max-35 rounded-pill btn-lg mb-4"
          @click="goTo('leaderboard')"
          >Lihat Leaderboard</b-button
        >
        </div>
        
        </div>
        
        <h3 class="text-center font-weight-bold">Play & Win</h3>
        <Gallery />
      </div>
    <!-- </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import Gallery from "@/components/Gallery.vue"
import ListLeaderboard from "@/components/ListLeaderboard.vue"
import router from '@/router'
// import Loader from '@/components/Loader.vue'
export default {
  name: "Home",
  components: {
    Gallery,
    ListLeaderboard,
    // Loader,
  },
  data() {
    return {
      swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      // isLoading: true,
      },

      dataLeaderboard: [],
      dataBanner: [
        {
          name: 'Pesta Reward',
          image: require('@/assets/pesta-reward.png'),
          url: 'leaderboard',
        },
        {
          name: 'Hadiah Langsung',
          image: require('@/assets/hadiah-langsung.png'),
          url: 'home',
        },
        {
          name: 'Redeem',
          image: require('@/assets/redeem.png'),
          url: 'redeem',
        },
      ],
      dataProfile: null,
    };
  },
  mounted() {
    // console.log(this.$store.state.appKey);
    //   console.log(this.bannerList);
    this.$store.state.isLoading = true
    // this.getLeaderboard()
    this.getDetail().then(() => {
      // this.getProfile()
      this.getLeaderboard()
      // this.getGameReward()
      // if (this.bannerFitur) {
      //   this.getBanner()
      // }
      this.$store.state.isLoading = false
      this.dataLeaderboard = this.homeLeaderboard()
      // this.dataLeaderboard = this.leaderboardList
    }, error => {
      console.error('err', error);
    })
    // console.log(this.dataBanner);
    // this.$router.replace()
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 3000)
    // // console.log('refresh');
    // // Promise.all([
    // //   this.getProfile(),
    // //   this.getGameReward()
    // // ]).then(() => {
    // //   console.log(this.reward);
    // // })
    // // this.dataLeaderboard = this.getLeaderboard()
    
    // console.log('data leaderboard',this.dataLeaderboard);
  },
  computed: {
    ...mapState([
      'coin',
      'reward',
      'point',
      'leaderboardList',
      'bannerList',
      'bannerFitur',
    ])
  },
  methods: {
    handleSubmit() {},
    handleClick(id) {
      // console.log(id);
      this.$router.push({ path: `/banner/${id}` })
    },
    goTo(page) {
      router.push({ name: page });
    },
    ...mapActions([
      'getLeaderboard',
      'getGameReward',
      'getDetail',
      'getBanner',
      'getProfile'
      // 'getGames'
    ]),
    homeLeaderboard(){
      const lbList = this.leaderboardList;
      const newList = lbList.slice(0,3)
      // console.log(newList)
      return newList
      // this.dataLeaderboard = newList
    },
    // setImage(img) {
    //   return require(img);
    // }
  }
};
</script>

<style scoped>
body {
  background-color: white;
}
.header-home {
  height: 75px;
  background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%);
  /* background-color: #fff; */
  /* background-color: #f7f7fc; */
  /* background: linear-gradient(92.88deg, #F11240 4.2%, #BF0244 97.09%); */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.home {
  padding-top: 6rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 0.5rem !important;
}
</style>
