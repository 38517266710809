<template>
  <div>
    <b-row
      class="justify-content-center h-100 mx-0"
    >
      <!-- <b-col md="12" align-self="center"> -->
      <!-- <b-card-group colums> -->
      <!-- <b-row 
            class="justify-content-center"> -->
      <b-col align-self="center" class="p-0">
        <ul class="list-unstyled">
          <div v-for="(list, index) in lists" :key="index">
            <b-card
            @click="handleClick(list.id)"
            overlay
          :img-src="list.image"
          img-alt="Image"
          style="border-radius: 1.5rem;"
          class="my-4 justify-content-center shadow"
        >
            </b-card>
          </div>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Listredeem",
  data() {
    return {
        lists: this.listRedeem,
    //   lists: [
    //     {
    //       id: 1,
    //       name: "Gratis Pulsa 5 Ribu",
    //       image: require("@/assets/app-screenshot.png"),
    //       price: 1000,
    //       description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
    //       url: "https://stgapi.timobile.tech/balaphoki/telkomsel/?id=1604179042"
    //     },
    //     {
    //       id: 2,
    //       name: "Gratis Pulsa 10 Ribu",
    //       image: require("@/assets/app-screenshot.png"),
    //       price: 1000,
    //       description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
    //       url: "https://stgapi.timobile.tech/balaphoki/telkomsel/?id=1604179042"
    //     },
    //     {
    //       id: 3,
    //       name: "Gratis Pulsa 25 Ribu",
    //       image: require("@/assets/app-screenshot.png"),
    //       price: 1000,
    //       description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
    //       url: "https://stgapi.timobile.tech/balaphoki/telkomsel/?id=1604179042"
    //     },
    //   ]
    };
  },
  props: ['listRedeem'],
  // mounted() {
  //   this.$store.state.listRedeem = this.listRedeem
  // },
  methods: {
    handleClick(id) {
      // console.log(id);
      this.$router.push({ path: `/redeem/${id}` })
    }
  }
};
</script>
 