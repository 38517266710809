<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <b-row class="mx-0">
      <b-col class="p-0">
        <b-nav pills justified class="p-1 bg-burem rounded">
          <b-nav-item to="/leaderboard" class="text-burem">Leaderboard</b-nav-item>
          <b-nav-item to="/redeem" class="actives text-hitam shadow-sm rounded">Redeem</b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
</div>
  <div class="pt-3">
      
    <Skor />
    <!-- <b-img fluid alt="Vue logo" :src="require('@/assets/pesta-reward.png')" class="mb-3">
    </b-img> -->
    <Listredeem :listRedeem="redeem" />
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Listredeem from '@/components/ListRedeem.vue'
import Header from '@/components/Header.vue'
import Skor from '@/components/Skor.vue'
export default {
  name: 'Redeem',
  components: {
    Listredeem,
    Header,
    Skor,
  },
  data() {
    return {
      titleHeader: "Pesta Reward",
      showToken: false,
      showBg: true,
      // totalCoin: this.getCoin,
      // dataList: this.listRedeem,
    };
  },
  mounted() {
    // console.log(this.redeem, this.coin);
  },
  computed: {
    ...mapState({
      coin: 'coin',
      redeem: 'redeemList',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
