<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <!-- <div class="pt-6">
    <b-row class="mx-0">
      <b-col class="p-0">
        <b-nav pills justified class="p-1 bg-burem rounded">
          <b-nav-item to="/leaderboard" class="actives text-hitam shadow-sm rounded">Leaderboard</b-nav-item>
          <b-nav-item to="/banner" class="text-burem">banner</b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
</div> -->
  <div class="pt-6">
    <b-img fluid alt="Vue logo" :src="detail.image" class="mb-3 w-100">
    </b-img>
    <div class="text-left">
      <!-- <h5 class="px-3">{{detail.name}}</h5> -->
    <div class="px-3" v-html="detail.description">
    </div>
    <!-- <b-button
        type="button"
        block
        variant="warning"
        class="max-35 rounded-pill btn-lg mb-4"
        @click="handleClick()"
        >banner</b-button
      > -->
    </div>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import { mapState } from 'vuex';
export default {
  name: 'DetailBanner',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Banner",
      showToken: false,
      showBg: true,
      // totalCoin: this.getCoin,
      detail: {},
      bannerId: this.$route.params.id,
    };
  },
  mounted() {
    // this.$store.state.gameId = this.$route.params.id
    if(this.bannerList.length !== 0) {
      this.getDetail(this.bannerId)
      // this.setPopupToken()
    } else {
      this.$router.push({ name : 'banner'})
    }
  },
  computed: {
    // ...mapGetters([
    //   'getCoin',
    //   'getRedeem',
    // ]),
    ...mapState([
      'bannerList',
      'coin',
    ])
  },
  methods: {
    getDetail(id) {
      const banner = this.bannerList
      const index = banner.findIndex(item => item.id === parseInt(id))
      // console.log(games[0]);
      this.detail = banner[index]
      // console.log(banner, index, id);
    },
    handleClick() {
      console.log('fungsi akses api banner');
      // this.$router.push({ name : 'banner'})
    }
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
ul {
  font-size: .9rem;
}
</style>
